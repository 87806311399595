import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { REGISTRATION_URL, ROADREWARD_URL } from '@prcins/constants';
import { HttpClient } from '@angular/common/http';
import {
  GaragingQuery,
  PolicyDriversQuery,
  PolicyDiscountsQuery,
  PolicyPerksQuery,
  PolicyPremiumDetailsQuery,
  PropertyInfoQuery,
  CoverageDetailsQuery,
  CoverageDetailsHomeQuery,
  PolicyCrossSellPromotionsQuery
} from "@prcins/utils";

@Injectable({
  providedIn: 'root'
})
export class PolicyProviderService {
  constructor(
    private http: HttpClient,
    private apollo: Apollo) { }

  getCrossSellPromotions(policyNumber?: string): Observable<any> {
    return this.apollo
      .watchQuery({
        query: PolicyCrossSellPromotionsQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }: any) => data));
  }

  getDriverDetails(policyNumber): Observable<any> {
    return this.apollo
      .watchQuery({
        query: PolicyDriversQuery,
        fetchPolicy: 'network-only',
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }: any) => data.policyDetails));
  }

  getVehicleDetails(policyNumber, guid): Observable<any> {
    return this.apollo
      .watchQuery({
        query: GaragingQuery,
        variables: {
          policyNumber,
          guid
        },
        fetchPolicy: "no-cache"
      })
      .valueChanges.pipe(map(({ data }: any) => data.policyDetailsV2));
  }

  getPerksDetails(policyNumber): Observable<any> {
    return this.apollo.watchQuery({
      query: PolicyPerksQuery,
      variables: {
        policyNumber,
      },
    }).valueChanges.pipe(map(({ data }: any) => data.policyPerks));
  }


  getDiscountDetails(policyNumber): Observable<any> {
    return this.apollo
      .watchQuery({
        query: PolicyDiscountsQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }: any) => data.policyDetails.discounts));
  }

  getPolicyPremiumDetails(policyNumber): Observable<any> {
    return this.apollo
      .watchQuery({
        query: PolicyPremiumDetailsQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }: any) => data.policyDetails.premium));
  }

  getPropertyInfo(policyNumber) {
    return this.apollo
      .watchQuery({
        query: PropertyInfoQuery,
        variables: {
          policyNumber
        },
        fetchPolicy: "no-cache"
      })
      .valueChanges.pipe(map(({ data }) => data));
  }

  getCoverageDetails(policyNumber: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: CoverageDetailsQuery,
        variables: {
          policyNumber
        },
        fetchPolicy: "no-cache"
      })
      .valueChanges.pipe(map(({ data }) => data));
  }

  getHomeCoverageDetails(policyNumber: String): Observable<any> {
    return this.apollo
      .watchQuery({
        query: CoverageDetailsHomeQuery,
        variables: {
          policyNumber
        }
      })
      .valueChanges.pipe(map(({ data }) => data));
  }

  addUpdateTextAlertDetails(payload) {


  }
  getRRDriversDetails(policyNumber): Observable<any> {
    return this.http.get(ROADREWARD_URL.DriverDetails + '/' + policyNumber).pipe(
      map((response: any) => {
        return { errorCode: response.errorCode, data: response.data };
      }),
      catchError(error => {
        return of({ errorCode: "RR-FAILURE", data: [] });
      })
    );
  }
}

